import { ReactNode } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';
import { TaskID } from '../../../../tasks/tasksTypes';
import { ProjectID } from '../../../../projects/projectsTypes';
import { FetchUsersFilters } from '../../../../users/usersTypes';
import {
  OperationClientID,
  FetchOperationsCacheKey,
  OperationBody,
  OperationDate,
  OperationTeamNanoID
} from '../../../operationsTypes';
import {
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../../../../helpers/dropzone/DropzoneHelper';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';
import { useCreateOperationForm } from '../../forms/CreateOperationForm/hooks/useCreateOperationForm';

import { CreateOperationForm } from '../../forms/CreateOperationForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { operationsKeys } from '../../../../../locales/keys';

const CREATE_OPERATION_FORM = 'create-operation-form';

interface CreateOperationModalButtonProps {
  taskId?: TaskID;
  projectId?: ProjectID;
  clientId?: OperationClientID;
  createCompanyRecord?: boolean;
  teamNanoId?: OperationTeamNanoID;
  body?: OperationBody;
  completeDatetime?: OperationDate;
  cacheKeys?: FetchOperationsCacheKey[];
  className?: ClassName;
  i18nTitle?: I18nText;
  i18nText?: I18nText;
  i18nSubmitText?: I18nText;
  tooltipI18nText?: I18nText;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  afterSubmit?: () => void;
  withToastOnSuccess?: boolean;
  disabled?: boolean;
  withUserSelect?: boolean;
  withMentionedUserIds?: boolean;
  userSelectInitialFilters?: FetchUsersFilters;
  initialFiles?: DropzoneHelperFiles;
  initialFileIds?: DropzoneHelperFileIds;
  buttonChildren?: ReactNode;
  withCreateCompanyRecord?: boolean;
}

function CreateOperationModalButton({
  taskId,
  projectId,
  clientId,
  createCompanyRecord,
  teamNanoId,
  body,
  completeDatetime,
  cacheKeys,
  className,
  i18nTitle,
  i18nText,
  i18nSubmitText,
  tooltipI18nText,
  i18nTextClassName,
  icon,
  iconClassName,
  afterSubmit,
  withToastOnSuccess,
  disabled,
  withUserSelect,
  withMentionedUserIds,
  userSelectInitialFilters,
  initialFileIds,
  initialFiles,
  buttonChildren,
  withCreateCompanyRecord
}: CreateOperationModalButtonProps) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: operationsKeys.recordAdded
  });

  const {
    validationErrors,
    registerFields,
    watchCreateCompanyRecord,
    handleCreateOperation,
    resetCreateOperationForm,
    createOperationReset,
    createOperationLoading,
    createOperationErrorMessage,
    control
  } = useCreateOperationForm({
    defaultValues: {
      clientId,
      body,
      completeDatetime,
      fileAttachmentIds: initialFileIds,
      createCompanyRecord
    },
    cacheKeys,
    afterSubmit,
    onSuccess: withToastOnSuccess ? showToastI18nNotification : undefined,
    projectId,
    taskId,
    teamNanoId
  });

  return (
    <FormModalButton
      className={
        className ||
        'py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      form={CREATE_OPERATION_FORM}
      i18nTitle={i18nTitle || operationsKeys.addRecord}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nSubmitText={i18nSubmitText || operationsKeys.addRecord}
      tooltipI18nText={tooltipI18nText}
      icon={icon}
      iconClassName={iconClassName}
      onOpen={resetCreateOperationForm}
      isLoading={createOperationLoading}
      onClose={createOperationReset}
      onSubmit={handleCreateOperation}
      disabled={disabled}
      buttonChildren={buttonChildren}
    >
      <CreateOperationForm
        form={CREATE_OPERATION_FORM}
        teamNanoId={teamNanoId}
        watchCreateCompanyRecord={watchCreateCompanyRecord}
        isLoading={createOperationLoading}
        control={control}
        registerBody={registerFields.registerBody}
        registerSubject={registerFields.registerSubject}
        registerCreateCompanyRecord={registerFields.registerCreateCompanyRecord}
        subjectValidationError={validationErrors.subjectValidationError}
        bodyValidationError={validationErrors.bodyValidationError}
        dateValidationError={validationErrors.dateValidationError}
        completeDatetimeValidationError={
          validationErrors.completeDatetimeValidationError
        }
        clientIdValidationError={validationErrors.clientIdValidationError}
        createCompanyRecordError={validationErrors.createCompanyRecordError}
        withUserSelect={withUserSelect}
        withMentionedUserIds={withMentionedUserIds}
        userSelectInitialFilters={userSelectInitialFilters}
        initialFiles={initialFiles}
        initialFileIds={initialFileIds}
        withCreateCompanyRecord={withCreateCompanyRecord}
      />

      <div className="px-4">
        <AlertMessage message={createOperationErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default CreateOperationModalButton;
